/* In your CSS */
:root {
  --color-dark-gray: #131416;
  --color-light-gray: #25282c;
  --color-lighter-gray: #84888d;
  --color-teal: #00729e;
  --color-light-teal: #99c7d8;
  --color-dark-teal: #004f6e;
  --window-background-color: #222;
  /* ... */
  --ag-selected-row-background-color: #00729e;
  --ag-odd-row-background-color: #222;
  --ag-even-row-background-color: #2e2e2e;
  --ag-background-color: #2e2e2e;
  --ag-row-border-color: #131416;
  --ag-row-hover-color: #00729e50;
  --ag-balham-active-color: #00729e;
  --ag-header-background-color: #232323;
  --ag-header-foreground-color: #ffffff;
  --ag-header-column-separator-color: #353535;
  --ag-value-change-value-highlight-background-color: #06924c50;
}

#root {
  background-color: var(--window-background-color);
  min-height: 100vh;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

.kill-focus {
  outline-color: red;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*::-webkit-scrollbar {
  background-color: #000;
  width: 11px;
  height: 11px;
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
  background-color: #000;
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
  background-color: #53575c;
  border-radius: 16px;
  border: 2px solid #000;
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
  display: none;
}
